import React, { useState } from "react"
import { navigate } from 'gatsby'
import { Layout } from '../../components/Layout'
import NetlifyForm from 'react-ssg-netlify-forms'
import './contact.css'

const ContactPage = () => {

  // Pre-Submit for validations and disabling button
  const [processing, setProcessing] = useState(false)
  const preSubmit = async () => {
    if (formValues.name.length > 0 && formValues.email.length > 0) {
      setProcessing(true)
      // Wait 2 seconds to simulate async delay (maybe user confirmation? or
      // external checks?)
      await (new Promise(resolve => setTimeout(resolve, 2000)))
      return true
    }
    else {
      return false
    }
  }

  // Post-Submit for navigating to 'Thank You' page .. or maybe displaying 'sent!'
  // text; totally up to you!
  const postSubmit = () => {
    console.log('Sent!')
    setProcessing(false)
    navigate('/contact/thanks')
  }

  // Simple controlled form setup
  const handleChange = e => setFormValues({ ...formValues, [e.target.name]: e.target.value })
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    message: '',
  })

  return (
    <Layout
      bodyClass={`page-template-default page has-no-pagination not-showing-comments footer-top-visible customize-support`}
    >
      <article className="p-5 card sm:p-10">
        <header className="blog-header has-text-align-center header-footer-group">
          <div className="blog-header-inner section-inner medium">
            <h1 className="entry-title">
              Contact
            </h1>
          </div>
        </header>
        <div className="contact-page">
          <div className="contact-form w-full max-w-lg">
            <NetlifyForm
              formName="Apply"
              formValues={formValues}
              preSubmit={preSubmit}
              postSubmit={postSubmit}
              automaticHoneypot={true}
              className="w-full max-w-lg"
            >

              {/* ROW */}
              <div className="flex flex-wrap -mx-3">
                <div className="w-full px-3">
                  <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor={'name'}>
                    Your Name
                  </label>
                  <input
                    className="appearance-none block w-full bg-white-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type={'text'}
                    name={'name'}
                    onChange={handleChange}
                    value={formValues.name}
                    id={'name'}
                    required={true}
                  />
                </div>
              </div>
              {/* ROW */}
              <div className="flex flex-wrap -mx-3">
                <div className="field w-full px-3">
                  <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor={'email'}>
                    Your Email
                  </label>
                  <input
                    className="appearance-none block w-full bg-white-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type={'email'}
                    name={'email'}
                    onChange={handleChange}
                    value={formValues.email}
                    id={'email'}
                    required={true}
                  />
                </div>
              </div>
              {/* ROW */}
              <div className="flex flex-wrap -mx-3">
                <div className="field w-full px-3">
                  <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor={'message'}>
                    Your Message
                  </label>
                  <input
                    className="appearance-none block w-full bg-white-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type={'message'}
                    name={'message'}
                    onChange={handleChange}
                    value={formValues.message}
                    id={'message'}
                    required={true}
                  />
                </div>
              </div>

              {/* RECAPTCHA */}
              <div data-netlify-recaptcha="true"></div>

              {/* SEND BUTTON */}
              <div className="field">
                <button
                  className="button is-link"
                  type="submit"
                  disabled={processing}
                >
                  SEND
                </button>
              </div>
            </NetlifyForm>
          </div>
        </div>
      </article>
    </Layout>
  )
}

export default ContactPage
